import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { NgRxUtils } from "../../../../shared/utility/NgRxUtils";
import { CalendarState } from "../reducers/calendar.reducer";
import { CalendarDailyEfforts, CalendarView, TimelineGroups, TimelinePreferences } from "../../calendar.model";
import { ExecutorForCalendarDTO } from "../../../../core/operators/operators.model";
import { DeviceView } from "../../../../core/devices/device.model";
import { FilterRequest, FilterSearch } from "../../../../core/filter-search/filter-search.model";
import { Nullable } from "../../../../shared/models/types";
import { TimelineDataDTO } from "../../../../core/calendar/calendar.model";
import { JobCalendarMoveDTO, JobForCalendarDTO } from "../../../../core/job/job.model";
import {
  PrintJobForCalendarRequestParams,
  PrintJobParams,
  PrintJobTemplateRequestDTO,
  PrintTemplateDTO,
  PrintTimelineTemplateRequestDTO
} from "../../../../core/print/print.model";
import {
  SeparateDateRangeValue
} from "../../../../shared/components/separate-daterangepicker/separate-daterangepicker.model";
import { CalendarPreferences } from "../../../../core/operators/operator-other-settings.model";

export const CalendarDefaultActions = createActionGroup({
  source: 'Calendar',
  events: {
    'merge state': props<{ state: Partial<CalendarState> }>(),
    'set show filter panel': props<{ showFilterPanel: boolean }>(),
    'set timeline dates': props<{ timelineDates: Date[] }>(),
    'set classic view date': props<{ currentClassicViewDate: Date, classicViewDate: Date }>(),
    'set timeline group': props<{ timelineGroup: TimelineGroups }>(),
    'set timeline column width': props<{ timelineColWidth: number }>(),
    'set enable recurrence': props<{ enableRecurrence: boolean }>(),
    'set tmp timeline preferences': props<{ tmpTimelinePreferences: Nullable<TimelinePreferences> }>(),
    'set show modal filter': props<{ showModalFilter: boolean }>(),
    'set active user filter': props<{ activeUserFilter: Nullable<FilterSearch> }>(),
    'set show grouped tasks': props<{ showGroupedTasks: boolean }>(),
    'set filter': props<{ filter: FilterRequest }>(),
    'set tmp filter': props<{ tmpFilter: Nullable<FilterRequest> }>(),
    'open asset': props<{ deviceId: number, customerId: number }>(),
    'open asset detail': props<{ deviceId: number }>(),
    'open asset detail in window': props<{ deviceId: number, customerId: number }>(),
    'open asset detail on new tab': props<{ deviceId: number, customerId: number }>(),
    'set include jobs null dates': props<{ includeJobsNullDates: boolean }>(),
    'set grid date range': props<{ dateRangeGrid: Nullable<SeparateDateRangeValue<Date>> }>(),
    'set show modal print': props<{ showModalPrint: boolean }>(),
    'set show search': props<{ showSearch: boolean }>(),
    'set is printing': props<{ isPrinting: boolean }>(),
    'set jobs before show modal print': emptyProps(),
    'add jobs before print': props<{ ids: string[] }>(),
    'search text': props<{ text: Nullable<string> }>(),
    'remove jobs': props<{ jobIds: string[] }>(),
    'clear errors': emptyProps(),
    'clear timeline state': emptyProps(),
    'clear grid state': emptyProps(),
    'clear classic view state': emptyProps(),
    'clear state': emptyProps()
  }
});

export const CalendarApiActions = createActionGroup({
  source: 'Calendar/API',
  events: {
    'init calendar': props<{ view: CalendarView, filterId?: number }>(),
    'init calendar success': props<{ filters: FilterSearch[], defaultFilter: Nullable<FilterSearch>, calendarPreferences: Nullable<CalendarPreferences> }>(),
    'init calendar failure': NgRxUtils.httpStatusProps(),

    'get timeline data': props<{ filter: FilterRequest }>(),
    'get timeline data success': props<{ timelineData: TimelineDataDTO }>(),
    'get timeline data failure': NgRxUtils.httpStatusProps(),
    'get timeline data too much occurence': emptyProps(),

    'get timeline daily efforts': props<{ filter: FilterRequest }>(),
    'get timeline daily efforts success': props<{ dailyEfforts: CalendarDailyEfforts }>(),
    'get timeline daily efforts failure': NgRxUtils.httpStatusProps(),

    'refresh timeline data': props<{ filter: FilterRequest }>(),
    'refresh timeline data success': props<{ timelineData: TimelineDataDTO }>(),
    'refresh timeline data failure': NgRxUtils.httpStatusProps(),

    'get calendar classic jobs': props<{ filter: FilterRequest, group: boolean, excludeExecutors: boolean }>(),
    'get calendar classic jobs success': props<{ classicViewJobs: JobForCalendarDTO[] }>(),
    'get calendar classic jobs failure': NgRxUtils.httpStatusProps(),

    'get grid print templates': props<{ body: PrintJobTemplateRequestDTO }>(),
    'get grid print templates success': props<{ printTemplates: PrintTemplateDTO[] }>(),
    'get grid print templates failure': NgRxUtils.httpStatusProps(),

    'get timeline print templates': props<{ body: PrintTimelineTemplateRequestDTO }>(),
    'get timeline print templates success': props<{ printTemplates: PrintTemplateDTO[] }>(),
    'get timeline print templates failure': NgRxUtils.httpStatusProps(),

    'refresh calendar classic jobs': props<{ filter: FilterRequest, group: boolean, excludeExecutors: boolean }>(),
    'refresh calendar classic jobs success': props<{ classicViewJobs: JobForCalendarDTO[] }>(),
    'refresh calendar classic jobs failure': NgRxUtils.httpStatusProps(),

    'get jobs for grid': props<{ filter: FilterRequest }>(),
    'get jobs for grid success': props<{ jobs: JobForCalendarDTO[] }>(),
    'get jobs for grid failure': NgRxUtils.httpStatusProps(),

    'refresh jobs for grid': props<{ filter: FilterRequest }>(),
    'refresh jobs for grid success': props<{ jobs: JobForCalendarDTO[] }>(),
    'refresh jobs for grid failure': NgRxUtils.httpStatusProps(),

    'get calendar filters': emptyProps(),
    'get calendar filters success': props<{ filters: FilterSearch[] }>(),
    'get calendar filters failure': NgRxUtils.httpStatusProps(),

    'get executors': emptyProps(),
    'get executors success': props<{ executors: ExecutorForCalendarDTO[] }>(),
    'get executors failure': NgRxUtils.httpStatusProps(),

    'get devices': props<{ deviceIds: number[] }>(),
    'get devices success': props<{ devices: DeviceView[] }>(),
    'get devices failure': NgRxUtils.httpStatusProps(),

    'edit job with drag & drop': props<{ startDateISO8601: string, endDateISO8601: string, jobMoveDto: JobCalendarMoveDTO }>(),
    'edit job with drag & drop success': props<{ jobs: JobForCalendarDTO[] }>(),
    'edit job with drag & drop failure': NgRxUtils.httpStatusProps(),

    'create user filter': props<{ filter: Partial<FilterSearch> }>(),
    'create user filter success': props<{ filter: FilterSearch }>(),
    'create user filter failure': NgRxUtils.httpStatusProps(),

    'update user filter': props<{ filter: FilterSearch }>(),
    'update user filter success': props<{ filter: FilterSearch }>(),
    'update user filter failure': NgRxUtils.httpStatusProps(),

    'delete user filter': props<{ id: number }>(),
    'delete user filter success': props<{ id: number }>(),
    'delete user filter failure': NgRxUtils.httpStatusProps(),

    'print timeline jobs': props<{ params: PrintJobForCalendarRequestParams }>(),
    'print timeline jobs success': props<{ id: string }>(),
    'print timeline jobs failure': NgRxUtils.httpStatusProps(),

    'print grid jobs': props<{ params: PrintJobParams }>(),
    'print grid jobs success': props<{ id: string }>(),
    'print grid jobs failure': NgRxUtils.httpStatusProps(),
  }
});